<template>
    <div id="memberOrderListPage">
      <div class="top-nav-bar">
        <van-nav-bar :title="$route.meta.title" @click-left="$router.back()">
          <van-icon class="back-arrow" name="arrow-left"  slot="left" />
        </van-nav-bar>
      </div>
      <div class="container" >
        <Empty
          v-if="detail.totalCount === 0"
          tip="購入履歴なし"
        ></Empty>
        <van-list
          v-else
          :finished="detail.finished"
          v-model="detail.loading"
          @load="onLoad"
          loading-text="ローディング..."
        >
          <van-collapse v-model="activeNames" :border="false">
            <van-collapse-item v-for="(item,index) in detail.list" :name="detail.orderId" :key="item.orderId">
              <template #title>
                <div class="content">
                  <div class="title">
                    <div class="left" v-if="item.type===2" @click.stop.prevent="jumpTo(item.type , item.videoId)">{{ item.name }}</div>
                    <div class="left" v-else @click.stop.prevent="jumpTo(item.type , item.id)">{{ item.gradesName }}</div>
                    <div class="right">
                      <span class="price">
                        <span class="big">{{ item.payment }}</span>円
                      </span>
                    </div>
                  </div>
                  <div class="info">
                    <div class="left">{{ item.createTime }}</div>
                    <div class="right" :class="{active: activeNames.includes(index)}">
                      <span class="name">詳細</span>
                      <van-icon name="arrow" />
                    </div>
                  </div>
                </div>
              </template>
              <template #right-icon>
                <span></span>
              </template>
              <div class="content">
                <ul class="list">
                  <li class="list-item">注文番号：{{ item.orderId }}</li>
                  <li class="list-item">支払時間：{{ item.createTime }}</li>
                  <li class="list-item">注文タイプ：{{ item.orderType }}</li>
                </ul>
                <ul class="list">
                  <li class="list-title">注文内容</li>
                  <template v-if="item.type===2">
                    <li class="list-item">動画名：{{ item.name }}</li>
                    <li class="list-item">動画タイプ：{{ item.typeName }}</li>
                    <li class="list-item">動画の画質{{ item.definition }}</li>
                    <li class="list-item">カテゴリー：{{ item.categoryName }}-{{ item.topCategoryName }}</li>
                    <li class="list-item">お値段：{{ item.price }}円</li>
                    <li class="list-item">クーポンの金額：{{ item.discountPrice }}</li>
                  </template>
                  <template v-else>
                    <li class="list-item">会員ランク：{{ item.gradesName }}</li>
                    <li class="list-item">サブスク期間：{{ item.day }}日</li>
                  </template>
                    <li class="list-item">ご請求額：{{ item.payment }}円</li>
                </ul>

                <div class="bottom">
                  <div class="btn" @click.prevent.stop="downLoad(item)">
                    領収書
                  </div>
                </div>
              </div>

            </van-collapse-item>
          </van-collapse>
        </van-list>
      </div>
    </div>
</template>

<script>
import { NavBar, Collapse, CollapseItem } from 'vant';
import { Empty } from '@/components';
export default {
  name: 'MemberOrderList',
  components: {
    'van-nav-bar': NavBar,
    'van-collapse': Collapse,
    'van-collapse-item': CollapseItem,
    Empty
  },
  data () {
    return {
      activeNames: [],
      detail: {
        finished: false,
        loading: false,
        list: [],
        pageSize: 20,
        pageNo: 1,
        totalCount: ''
      }
    };
  },
  created () {
  },
  mounted () {
  },
  watch: {},
  methods: {

    /* 初始化数据 */
    init () {
      this.detail = {
        finished: false,
        loading: false,
        list: [],
        pageSize: 20,
        pageNo: 1
      };
    },
    onLoad () {
      const { detail } = this;
      let { pageNo, pageSize } = detail;
      this.$http.get('mine/memberRecord', {
        params: {
          pageNo,
          pageSize
        }
      }).then((res) => {
        let { isHasNext, nextPage, result, totalCount } = res.data || {};
        detail.finished = !isHasNext;
        detail.loading = false;
        detail.pageNo = nextPage;
        detail.totalCount = totalCount;
        detail.list.push(...result);
      });
    },

    // /* 下载文件 */
    downLoad (item) {
      this.$http.get('pdf/generate', {
        params: {
          orderNo: item.orderId,
          type: item.type
        },
        showLoading: true
      }).then((res) => {
        // console.log(res.data);
        const link = document.createElement('a');
        link.href = res.data;
        link.setAttribute('download', res.data);
        document.body.appendChild(link);
        link.click();
      });
    },

    /* 跳转 */
    jumpTo (type, id) {
      let jump;
      switch (type) {
        case 1:
          jump = { name: 'MemberIndex' };
          break;
        case 2:
          jump = { name: 'Detail', query: { id } };
          break;
      }
      this.$router.push(jump);
    }

    // /* 下载pdf */
    // downLoadFn (href, fileName) {
    //   let a = document.createElement('a'); // 获取文件名fileName
    //   a.download = fileName || '';
    //   a.href = href;
    //   alert(href);
    //   document.body.appendChild(a);
    //   a.click();
    //   // const link = document.createElement('a');
    //   // link.href = res.data;
    //   // link.setAttribute('download', res.data);
    //   // document.body.appendChild(link);
    //   // link.click();
    //   // document.body.removeChild(a);
    // },

    // /* 下载文件 */
    // downLoad (item) {
    //   this.$http.get('pdf/generate', {
    //     params: {
    //       orderNo: item.orderId,
    //       type: item.type
    //     },
    //     showLoading: true
    //   }).then((res) => {
    //     let url = res.data; // FileReader主要用于将文件内容读入内存
    //     // var userAgent = window.navigator.userAgent; //取得浏览器的userAgent字符串
    //     // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
    //     this.$http.get(url, {
    //       responseType: 'blob'
    //     }).then((res) => {
    //       let blob = res; // FileReader主要用于将文件内容读入内存
    //       if (navigator.msSaveBlob) {
    //         window.navigator.msSaveOrOpenBlob(blob, url);
    //       } else {
    //         this.downLoadFn(URL.createObjectURL(blob), url);
    //       }
    //     });
    //   });
    // }
  }
};
</script>

<style scoped lang="less">
#memberOrderListPage {
  /deep/
  .top-nav-bar {
    .van-nav-bar {
      background-color: #2d2e2e;
    }
  }

  .container {
    /deep/
    .van-collapse {
      .van-collapse-item {
        margin-bottom: 20px;

        &::after {
          display: none;
        }

        .van-cell {
          background-color: #2d2e2e;

          &::after {
            display: none;
          }

          .van-cell__title {
            .content {
              color: #fff;

              .title,
              .info {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .title {
                margin-bottom: 10px;

                .left {
                  font-size: 28px;
                  opacity: 0.7;
                }

                .right {
                  .price {
                    font-size: 24px;
                    font-weight: bold;
                    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

                    .big {
                      font-size: 36px;
                    }
                  }
                }
              }

              .info {
                font-size: 24px;
                line-height: 32px;
                opacity: 0.7;

                .right {
                  display: flex;
                  align-items: center;

                  .van-icon {
                    position: relative;
                    top: 1.5px;
                    margin-left: 12px;
                    transition: 0.2s all;
                  }

                  &.active {
                    .van-icon {
                      transform: rotateZ(90deg);
                    }
                  }
                }
              }
            }
          }
        }

        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            padding: 32px 28px 20px;
            font-size: 24px;
            line-height: 32px;
            color: #fff;
            background-color: #1c1d1f;

            .content {
              .list {
                &:not(:last-child) {
                  margin-bottom: 40px;
                }

                .list-title {
                  margin-bottom: 16px;
                }

                .list-item {
                  opacity: 0.5;

                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }
                }
              }

              .bottom{
                display: flex;
                align-items: center;
                .btn{
                  font-size: 24px;
                  border: 2px solid #fff;
                  color: #fff;
                  border-radius: 8px;
                  padding: 20px;
                  display: inline-block;
                  margin: 0 auto;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>